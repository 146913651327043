
import { Options, Vue } from "vue-class-component";
import MIcon from "@/components/MIcon.vue";
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/MButton.vue";
import MTextField from "@/components/form/MTextField.vue";
import MTextArea from "@/components/form/MTextArea.vue";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import store from "@/store";
import {
  systemSettingCollectionKey,
  convertToNotificationSetting,
  NotificationSetting
} from "@/entities/admin";

type PromptType = "enter" | "leave";

@Options({
  components: {
    MIcon,
    MBaseModal,
    MButton,
    MTextField,
    MTextArea
  },
  props: {
    isAdmin: Boolean
  },
  emits: ["close"],
  watch: {
    setIntervalForReply() {
      if (!this.setIntervalForReply) return;
      if (this.min !== undefined) {
        this.validateMin();
      }
      if (this.max !== undefined) {
        this.validateMax();
      }
      if (this.enterPrompt) {
        this.validatePrompt("enter");
      }
      if (this.leavePrompt) {
        this.validatePrompt("leave");
      }
    }
  }
})
export default class MNotificationSettingModal extends Vue {
  isAdmin = false;
  useAiReply = false;
  setIntervalForReply = false;
  notificationSetting: NotificationSetting | null = null;
  min = 5;
  minEntered = false;
  isMinValid = false;
  minErrorType: "invalidFormat" | "mismatch" | "" = "";
  max = 10;
  maxEntered = false;
  isMaxValid = false;
  maxErrorType: "invalidFormat" | "mismatch" | "" = "";
  enterPrompt = "";
  enterPromptEntered = false;
  isEnterPromptValid = false;
  leavePrompt = "";
  leavePromptEntered = false;
  isLeavePromptValid = false;

  get replyImmediately(): boolean {
    return !this.setIntervalForReply;
  }

  get minInvalidMessage(): string {
    if (this.minErrorType === "invalidFormat") {
      return "2以上の整数を入力してください";
    } else if (this.minErrorType === "mismatch") {
      return "下限が上限を上回らないようにしてください";
    } else {
      return "";
    }
  }

  get maxInvalidMessage(): string {
    if (this.maxErrorType === "invalidFormat") {
      return "2以上の整数を入力してください";
    } else if (this.maxErrorType === "mismatch") {
      return "下限が上限を上回らないようにしてください";
    } else {
      return "";
    }
  }

  get isValidData(): boolean {
    if (!this.useAiReply) return true;
    if (this.useAiReply && this.replyImmediately && this.isValidPrompt)
      return true;
    if (
      this.useAiReply &&
      !this.replyImmediately &&
      this.isValidPrompt &&
      this.isMinValid &&
      this.isMaxValid
    )
      return true;

    return false;
  }

  get isValidPrompt(): boolean {
    return this.isEnterPromptValid && this.isLeavePromptValid;
  }

  validateMin() {
    if (this.min < 2 || !Number.isInteger(this.min)) {
      this.minErrorType = "invalidFormat";
      this.isMinValid = false;
      return;
    }
    if (this.min > this.max) {
      this.minErrorType = "mismatch";
      this.isMinValid = false;
      return;
    }
    this.minErrorType = "";
    this.isMinValid = true;
  }

  validateMax() {
    if (this.max < 2 || !Number.isInteger(this.max)) {
      this.isMaxValid = false;
      this.maxErrorType = "invalidFormat";
      return;
    }
    if (this.min > this.max) {
      this.isMaxValid = false;
      this.maxErrorType = "mismatch";
      return;
    }
    this.isMaxValid = true;
    this.maxErrorType = "";
  }

  validatePrompt(type: PromptType) {
    switch (type) {
      case "enter":
        if (!this.enterPrompt) {
          this.isEnterPromptValid = false;
          return;
        }
        this.isEnterPromptValid = true;
        break;

      case "leave":
        if (!this.leavePrompt) {
          this.isLeavePromptValid = false;
          return;
        }
        this.isLeavePromptValid = true;
        break;

      default:
        break;
    }
  }

  close() {
    this.$emit("close");
  }

  onMinInput(val: number | string) {
    this.min = +val;
    this.validateMin();
  }

  onMinBlur() {
    this.validateMin();
  }

  onMaxInput(val: number | string) {
    this.max = +val;
    this.validateMax();
  }

  onMaxBlur() {
    this.validateMax();
  }

  onInputPrompt(val: string, type: PromptType) {
    switch (type) {
      case "enter":
        this.enterPrompt = val;
        this.validatePrompt("enter");
        break;

      case "leave":
        this.leavePrompt = val;
        this.validatePrompt("leave");
        break;

      default:
        break;
    }
  }

  onPromptBlur(type: PromptType) {
    this.validatePrompt(type);
  }

  async updateSetting() {
    if (!this.isAdmin) return;
    const setting: { [key: string]: any } = {};
    if (!this.useAiReply) {
      setting.useAiReply = false;
    } else {
      // AI返信を使う場合はプロンプトをバリデーション
      (["enter", "leave"] as PromptType[]).forEach(_ => this.validatePrompt(_));
      if (!this.isValidPrompt) {
        alert("プロンプトの入力に誤りがあります。再度確認してください。");
        return;
      }
      setting.enterPrompt = this.enterPrompt;
      setting.leavePrompt = this.leavePrompt;

      // 即時返信かどうかで処理を分岐する
      if (this.replyImmediately) {
        setting.useAiReply = true;
        setting.replyImmediately = true;
      } else {
        if (!this.replyImmediately) {
          this.validateMin();
          this.validateMax();
          if (!this.isValidData) {
            alert("入力されたデータに誤りがあります。再度確認してください。");
            return;
          }
          setting.useAiReply = true;
          setting.replyImmediately = false;
          setting.min = this.min;
          setting.max = this.max;
        }
      }
    }

    if (Object.keys(setting).length === 0) {
      // 普通ありえない
      return;
    }
    try {
      store.commit("START_LOADING", "更新中...");
      if (this.notificationSetting) {
        await this.notificationSetting.ref.update({ ...setting });
      } else {
        const newRef = firebase
          .firestore()
          .collection(systemSettingCollectionKey)
          .doc("notification");
        await newRef.set(setting);
      }
    } catch (e) {
      alert("設定の更新に失敗しました");
      console.error(e);
    } finally {
      store.commit("END_LOADING");
      this.$router.go(0);
    }
  }

  async created(): Promise<void> {
    if (!this.isAdmin) {
      this.$emit("close");
      return;
    }

    try {
      store.commit("START_LOADING", "情報取得中...");
      const snapshot = await firebase
        .firestore()
        .collection(systemSettingCollectionKey)
        .doc("notification")
        .get();
      if (snapshot && snapshot.exists && snapshot.data()) {
        this.notificationSetting = convertToNotificationSetting(
          snapshot.data() as firebase.firestore.DocumentData,
          snapshot.ref
        );
        const { data } = this.notificationSetting;
        this.useAiReply = data.useAiReply;
        this.setIntervalForReply = !data.replyImmediately;
        if (data.min) {
          this.min = data.min;
          this.validateMin();
        }
        if (data.max) {
          this.max = data.max;
          this.validateMax();
        }
        if (data.enterPrompt) {
          this.enterPrompt = data.enterPrompt;
          this.validatePrompt("enter");
        }
        if (data.leavePrompt) {
          this.leavePrompt = data.leavePrompt;
          this.validatePrompt("leave");
        }
      }
      store.commit("END_LOADING");
    } catch (e) {
      alert("設定情報の取得に失敗しました");
      console.error(e);
      store.commit("END_LOADING");
    }
  }
}
